<template>
  <div class="row">
    <div class="col-12">
      <card :key="componentKey" >
        <div slot="header" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
          <h4 v-if="registrationType == 'session'" class="card-title">{{ $store.getters['languageMixin/getStringFromLanguage']('Session Registration List') }}</h4>
          <h4 v-else-if="registrationType == 'cycle'" class="card-title">{{ $store.getters['languageMixin/getStringFromLanguage']('Cycle Registration List') }}</h4>
          <h4 v-else class="card-title">{{ $store.getters['languageMixin/getStringFromLanguage']('All Registration List') }}</h4>
        </div>
        <div class="d-flex justify-content-end">
          <a v-if="(filters.cycle_id || filters.session_id) && $store.getters['auth/haveOneOfPermissions'](['registrations/create'])"
             class="btn btn-info btn-wd btn-simple btn-link"
             style="background-color: #23CCEF !important; color: #ffffff !important;"
             @click="openAddRegistrationModal()">
            {{ $store.getters['languageMixin/getStringFromLanguage']('Add New Registration') }}
            <span class="btn-label">
                      <i class="fa fa-plus"></i>
                  </span></a>

          <a v-if="(filters.cycle_id || filters.session_id) && $store.getters['auth/haveOneOfPermissions'](['waiting-lists/index'])"
             class="btn btn-default btn-wd btn-simple btn-link"
             style="background-color: rgb(236, 100, 89) !important; color: #ffffff !important;margin-right: 17px;margin-left: 17px;"
            @click="openWaitingListModal()">
            {{ $store.getters['languageMixin/getStringFromLanguage']('Show The Waiting List') }}
            <span class="btn-label">
                      <i class="fa fa-hourglass-half"></i>
                  </span></a>
          <a v-if="$store.getters['auth/checkAccess']('registrations/export')"
             class="btn btn-info  export_excel" style="display:flex;min-width: 120px"
             @click="openModal('basic')"> {{ $store.getters['languageMixin/getStringFromLanguage']('Export') }}
            <span class="btn-label px-2">
                  <i class="nc-icon nc-cloud-download-93" style="font-size:20px;"></i>
                  </span>
          </a>
        </div>
        <!--    filters    -->
        <div v-if="registrationType === 'session'" class="row col-12 mb-2">
          <div class="col-md-4 col-sm-4">
            <fg-select
              size="large"
              filterable
              clearable
              :placeholder="$store.getters['languageMixin/getStringFromLanguage']('Select Training')"
              :input-classes="'select-default'"
              :list="filterBuilders.trainingSessionsList"
              :listItemLabel="'title'"
              :listItemValue="'id'"
              v-model="filters.session_id">
              <template v-slot:option="{ item }">
                <span class="option-style" v-html="item.title"></span>
              </template>
            </fg-select>
          </div>

          <div class="col-md-3 col-sm-4">
            <l-button class="mr-lg-1" @click="applyFilter()" type="info">{{ $store.getters['languageMixin/getStringFromLanguage']('Filter Result') }}</l-button>
          </div>
        </div>
        <div v-if="registrationType === 'cycle'" class="row col-12 mb-2">
          <div class="col-md-4 col-sm-4">
            <fg-select
              size="large"
              filterable
              clearable
              :placeholder="$store.getters['languageMixin/getStringFromLanguage']('Select Cycle')"
              :input-classes="'select-default'"
              :list="filterBuilders.cycleList"
              :listItemLabel="'name'"
              :listItemValue="'id'"
              v-model="filters.cycle_id">
            </fg-select>
          </div>

          <div class="col-md-3 col-sm-4">
            <l-button class="mr-lg-1" @click="applyFilter()" type="info">{{ $store.getters['languageMixin/getStringFromLanguage']('Filter Result') }}</l-button>
          </div>
        </div>
        <!--  end  filters    -->

        <!-- Send emails -->
        <div class="row col-12 mb-2">
          <div class="col-md-3 col-sm-4">
            <l-button :disabled="!selectedIds.length" class="mr-lg-1" @click="openTemplatesModal()" type="info">{{ $store.getters['languageMixin/getStringFromLanguage']('Send Emails') }}</l-button>
          </div>
        </div>
        <!-- End send emails-->

        <div class="registration-table">
          <general-data-table
            ref="table"
            :key="componentKey"
            :selectable="true"
            :advanceSearchFilter="advanceSearchFilter"
            :pageSize="this.$route.params['type'] === undefined ? 10 : pageSize"
            @selection-change-custom="handleSelectionChange"
            :api-url="apiUrl">
            <el-table-column v-for="column in tableColumns"
                             :key="column.label"
                             :min-width="column.minWidth"
                             :align="column.align"
                             :prop="column.value"
                             :sortable="column.sortable"
                             :label="column.label">

              <template slot-scope="scope">

                <div v-if="column.value === 'status_cms'">
                    <span class="register_status badge" :style="{backgroundColor: scope.row['status_cms'].color, marginTop: '24px'} ">
                      {{ scope.row['status_cms'].fr }}
                    </span>
                </div>
                <span class="row" v-else-if="column.value === 'payment_deposit_status_cms'">
                  <span class="col-md-6">
                      {{ $store.getters['languageMixin/getStringFromLanguage']('Deposit') }}  : <br>
                    <span style="margin-right: 8px" class="register_status badge" :style="{backgroundColor: scope.row['payment_deposit_status_cms'].color} ">
                      {{ scope.row['payment_deposit_status_cms'].fr }}
                    </span>
                  </span>
                  <span class="col-md-6">
                        {{ $store.getters['languageMixin/getStringFromLanguage']('Balance') }}  : <br>
                    <span class="register_status badge" :style="{backgroundColor: scope.row['payment_balance_status_cms'].color} ">
                      {{ scope.row['payment_balance_status_cms'].fr }}
                    </span>
                  </span>
                </span>

                <span v-else-if="column.value === 'member.full_name_cms'" v-html="scope.row['member'].full_name_cms"></span>
                <span v-else-if="column.value === 'session_cycle_name'" :style="{color: scope.row['type'] === 'cycle' ? '#6ebb4a' : 'black'}" v-html="scope.row['session_cycle_name']"></span>


                <span v-else v-html="scope.row[column.value]"></span>

              </template>
            </el-table-column>




            <el-table-column
              :min-width="120"
              align="center"
              fixed="right"
              :label="$store.getters['languageMixin/getStringFromLanguage']('Actions')">
              <template slot-scope="scope">

                <template>
                  <router-link v-if="$store.getters['auth/haveOneOfPermissions'](['registrations/get'])"
                               v-tooltip.top-center="$store.getters['languageMixin/getStringFromLanguage']('Show')"
                               class="btn-warning btn-simple btn-link"
                               :to="{ name: 'Show Registration Info', params: { type: filters.type, id: scope.row.id } }">
                    <i class="fa fa-edit"></i>
                  </router-link>

                  <a v-if="$store.getters['auth/haveOneOfPermissions'](['registrations/delete'])"
                     v-tooltip.top-center="$store.getters['languageMixin/getStringFromLanguage']('Delete')"
                     class="btn-danger btn-simple btn-link"
                     @click="openDeleteModal(scope.row.id)">
                    <i class="fa fa-times"></i></a>
                </template>


              </template>
            </el-table-column>

          </general-data-table>
        </div>
      </card>
    </div>
    <add-registration-modal :visible="modalVisibility"
                            :is_register_modal="true"
                            :cost="newRegistrationCost"
                  :message="$store.getters['languageMixin/getStringFromLanguage']('select a member to add to this session?')"
                  @close="closeAddRegistrationModal()"
                  @confirm="confirmRegistrationModal">
    </add-registration-modal>
    <email-templates-modal :visible="showTemplateModalVisibility"
                  :selectedUsers="selectedIds"
                  :templatesList="templatesList"
                  @close="closeTemplatesModal()"
                  @sendEmails="sendEmails"
    >
    </email-templates-modal>
    <delete-modal :visible="deleteModalVisibility"
                  :message="$store.getters['languageMixin/getStringFromLanguage']('Are you sure you want to delete this registration?')"
                  @close="closeDeleteModal()"
                  @confirm="confirmDeleteModal()">
    </delete-modal>
    <export-modal id="export-modal" :title="$store.getters['languageMixin/getStringFromLanguage']('Registrations')" url="registrations/export" :key="exportKey"
                  :filters="exportFilters" :modals="modals"></export-modal>
  </div>
</template>

<script>
import {DeleteModal as DeleteModal} from '@/components'
import GeneralDataTable from "@/components/GeneralDataTable";
import {TableColumn} from "element-ui";
import FgSelect from "@/components/Inputs/formGroupSelect";
import ExportModal from '@/components/ExportModal/ExportModal.vue';
import AddRegistrationModal from "../../../components/Modals/AddRegistraionModal";
import EmailTemplatesModal from "../../../components/Modals/EmailTemplatesModal";

export default {
  components: {
    AddRegistrationModal,
    EmailTemplatesModal,
    GeneralDataTable,
    DeleteModal,
    [TableColumn.name]: TableColumn,
    FgSelect,
    ExportModal
  },
  computed: {
    apiUrl() {
      const { type, session_id, cycle_id } = this.$route.params;
      return `registrations/index/${type}/${session_id || cycle_id}`;
    }
  },
  watch:{
    $route (to, from){
      this.componentKey++;
      this.addFilters();
      // this.applyFilter();
    }
  },
  data() {

    return {
      tableColumns: [
        {label: this.$store.getters['languageMixin/getStringFromLanguage']('Member Name'), value: 'member.full_name_cms', minWidth: '200', align: 'center'},
        {label: this.$store.getters['languageMixin/getStringFromLanguage']('Session / Cycle Name'), value: 'session_cycle_name', minWidth: '200', align: 'center'},
        {label: this.$store.getters['languageMixin/getStringFromLanguage']('Status'), value: 'status_cms', minWidth: '100', align: 'center', color: 'red', sortable: true},
        {label: this.$store.getters['languageMixin/getStringFromLanguage']('Payment Status'), value: 'payment_deposit_status_cms', minWidth: '200', align: 'center', color: 'red'},
        {label: this.$store.getters['languageMixin/getStringFromLanguage']('Last update / Created at'), value: 'creation_dates', minWidth: '150', align: 'center'},
      ],
      modals: {
        basic: false,
        centered: false,
        custom: false,
        confirm: false
      },
      filterBuilders: {
        trainingSessionsList: [],
        cycleList: [],
        memberList: [],
        registrationStatusList: [],
        paymentStatusList: [],
        paymentMethodList: [],
      },

      filters: {
        session_id: null,
        cycle_id: null,
        // type: 'normal',
        type: null,
      },
      modalVisibility: false,
      waitingListModalVisibility: false,
      waitingListId: undefined,
      deleteModalVisibility: false,
      toDeleteId: undefined,
      componentKey: 0,
      registrationType: null,
      showRegistrationLink: '',
      selectedIds: [],
      showTemplateModalVisibility: false,
      templatesList: [],
      advanceSearchFilter: [],
      exportFilters: [],
      exportKey: 0,
      pageSize: 50,
      newRegistrationCost: 0,
    }
  },
  async mounted() {
    let data = {};
    let response = await this.axios.post("registrations/builder", data);
    this.filterBuilders.cycleList = response.data.cycles;
    this.filterBuilders.registrationStatusList = response.data.registrationStatusList;
    this.filterBuilders.paymentStatusList = response.data.paymentStatusList;
    this.filterBuilders.paymentMethodList = response.data.paymentMethodList;
    this.filterBuilders.memberList = response.data.members;
    this.templatesList = response.data.templatesList;
    this.filterBuilders.trainingSessionsList = response.data.trainingSessions
      .map(obj => ({...obj, title: obj.training.title + ' ( ' + obj.days + ' )'}));
    this.setAdvanceSearch();

    this.setExportFilters();
    this.addFilters();
    // this.applyFilter();
    this.setExportFilters();
    if (this.filters.type != null){
      this.showRegistrationLink = '../../';
    }else if(this.filters.cycle_id != null || this.filters.session_id != null){
      this.showRegistrationLink = '../';
    }
  },
  methods: {
    addFilters(){
      if (this.$route.params['type'] === undefined){
        this.pageSize = 10;
      }else{
        this.pageSize = 50;
      }
      this.registrationType = this.$route.params['type'];
      if (this.registrationType === 'session'){
        this.filters.type = 'session';
        this.filters.session_id = this.$route.params['session_id'] ? parseInt(this.$route.params['session_id']) : null;
        this.filters.cycle_id = null;
      }else if (this.registrationType === 'cycle'){
        this.filters.type = 'cycle';
        this.filters.cycle_id = this.$route.params['session_id'] ? parseInt(this.$route.params['session_id']) : null;
        this.filters.session_id = null;
      }else{
        this.filters.type = null;
        this.filters.cycle_id = null;
        this.filters.session_id = null;
      }
    },
    openDeleteModal(id) {
      this.deleteModalVisibility = true;
      this.toDeleteId = id;
    },

    openAddRegistrationModal() {
      if (this.filters.session_id || this.filters.cycle_id){
        const selectedSession = this.filterBuilders.trainingSessionsList.filter((e) =>
          e.id === this.filters.session_id
        );
        const selectedCycle = this.filterBuilders.cycleList.filter((e) =>
          e.id === this.filters.cycle_id
        );
        this.newRegistrationCost = selectedSession.length > 0 ? selectedSession[0].cost : selectedCycle[0].cost;
        this.modalVisibility = true;
      }else{
        this.$notify({
          message: this.$store.getters['languageMixin/getStringFromLanguage']("You have to specify a cycle or session"),
          timeout: 2000,
          type: 'warning'
        });
      }
    },

    openWaitingListModal(id) {
      let route = '../../../waiting-lists/list';
      if (this.filters.type === 'session'){
        route += '/session/' + this.filters.session_id;
      }else{
        route += '/cycle/' + this.filters.cycle_id;
      }
      this.$router.push(route);
    },
    closeWaitingListModal() {
      this.modalVisibility = false;
    },


    closeAddRegistrationModal() {
      this.modalVisibility = false;
    },
    closeDeleteModal() {
      this.deleteModalVisibility = false;
    },
    async confirmRegistrationModal(registrationDetails) {
      try {
        let data = {
          'member_id': registrationDetails.member.id,
          'is_guest': registrationDetails.is_guest,
          'session_id': this.filters.session_id,
          'cycle_id': this.filters.cycle_id,
        }
         await this.axios.post("registrations/create", data).then(response => {
           this.$notify({
             message: this.$store.getters['languageMixin/getStringFromLanguage']("Registration added successfully"),
             timeout: 1000,
             type: 'success'
           });
           this.$router.push("/training-sessions/show-registrations/" + response.data.id);
           }).catch((error) => {
           console.log(error)
           if (error.response.status === 400) {
             this.$notify({
               message: error.response.data.message,
               timeout: 1000,
               type: 'danger'
             });
           } else {
           }
         }).finally(() => {
           this.submitting = false;
         });

      } catch (error) {
        console.log(error)
        this.$notify({
          message: error.response.data.message,
          timeout: 2000,
          type: 'danger'
        })
      } finally {
        this.closeDeleteModal();
        this.toDeleteId = undefined;
      }
    },
    async confirmDeleteModal() {
      try {
        let data = {
          'id': this.toDeleteId,
        }
        await this.axios.delete("registrations/delete", {headers: {}, data});
        this.$refs.table.getData("updateData");
        this.$notify({
          message: this.$store.getters['languageMixin/getStringFromLanguage']("Registration deleted successfully"),
          timeout: 1000,
          type: 'success'
        });
      } catch (error) {
        this.$notify({
          message: error.response.data.message,
          timeout: 2000,
          type: 'danger'
        })
      } finally {
        this.closeDeleteModal();
        this.toDeleteId = undefined;
      }
    },
    applyFilter() {
      this.$refs.table.filters = {...this.filters};
      if (this.filters.session_id || this.filters.type || this.filters.cycle_id) {
        this.$refs.table.createDragAndDropSorting();
      } else {
        this.$refs.table.destroyDragAndDropSorting();
      }
      // this.componentKey++;
      this.$refs.table.getData("updateData");
    },
    handleSelectionChange(selectedRows){
      this.selectedIds = selectedRows;
      // selectedRows.map((row) => {
      //   return row.member;
      // });
      // console.log(this.selectedIds)
      // this.selectedIds = [... new Set(this.selectedIds)]
    },
    sendEmails(templateID){
      let data = {'template':templateID, 'userData' :this.selectedIds, 'type': this.registrationType} ;
      let request;

      request = this.axios.post("email-templates/send", data);
      let successMessage = this.$store.getters['languageMixin/getStringFromLanguage']("Emails Sent Successfully");
      request.then((response) => {
        console.log(response)
        this.$notify({
          message: successMessage,
          timeout: 1000,
          type: 'success'
        })

      }).catch((error) => {
        console.log(error)
        this.$notify({
        message: "issue",
        timeout: 1000,
        type: 'failed'
      })

      }).finally(() => {
        this.closeTemplatesModal()
      })
    },
    openTemplatesModal() {
      this.showTemplateModalVisibility = true;
    },
    closeTemplatesModal() {
      this.showTemplateModalVisibility = false;
    },
    setAdvanceSearch(){
      this.advanceSearchFilter = [
        {
          key: 'registrations.status',
          type: 'multiple-select',
          options: this.filterBuilders.registrationStatusList,
          optionLabel: 'fr',
          optionValue: 'value',
          frontType:'select-with-fixed-list',
          strategy: '',
          label: this.$store.getters['languageMixin/getStringFromLanguage']('Status'),
          class: 'col-md-4 col-sm-6 col-xs-12',
          multiple: true ,
          value: null,
        },
        {
          key: 'registrations.payment_deposit_status',
          type: 'multiple-select',
          options: this.filterBuilders.paymentStatusList,
          optionLabel: 'fr',
          optionValue: 'value',
          frontType:'select-with-fixed-list',
          strategy: '',
          label: this.$store.getters['languageMixin/getStringFromLanguage']('Payment Status (Deposit)'),
          class: 'col-md-4 col-sm-6 col-xs-12',
          multiple: true ,
          value: null,
        },
        {
          key: 'registrations.payment_balance_status',
          type: 'multiple-select',
          options: this.filterBuilders.paymentStatusList,
          optionLabel: 'fr',
          optionValue: 'value',
          frontType:'select-with-fixed-list',
          strategy: '',
          label: this.$store.getters['languageMixin/getStringFromLanguage']('Payment Status (Balance)'),
          class: 'col-md-4 col-sm-6 col-xs-12',
          multiple: true ,
          value: null,
        },
        {
          key: 'registrations.created_at',
          type: 'date',
          frontType:'simple',
          strategy: 'gt',
          label: this.$store.getters['languageMixin/getStringFromLanguage']('Created after'),
          class: 'col-md-6 col-sm-12',
          value: null,
        },
        {
          key: 'registrations.created_at',
          type: 'date',
          frontType:'simple',
          strategy: 'lt',
          label: this.$store.getters['languageMixin/getStringFromLanguage']('Created before'),
          class: 'col-md-6 col-sm-12',
          value: null,
        },
        {
          key: 'registrations.updated_at',
          type: 'date',
          frontType:'simple',
          strategy: 'gt',
          label: this.$store.getters['languageMixin/getStringFromLanguage']('updated after'),
          class: 'col-md-6 col-sm-12',
          value: null,
        },
        {
          key: 'registrations.updated_at',
          type: 'date',
          frontType:'simple',
          strategy: 'lt',
          label: this.$store.getters['languageMixin/getStringFromLanguage']('updated before'),
          class: 'col-md-6 col-sm-12',
          value: null,
        },
      ];
    },
    setExportFilters(){
      this.exportFilters = [
        {
          key: 'status',
          type: 'multiple-select',
          options: this.filterBuilders.registrationStatusList,
          optionLabel: 'fr',
          optionValue: 'value',
          frontType:'select-with-fixed-list',
          strategy: '',
          label: this.$store.getters['languageMixin/getStringFromLanguage']('Status'),
          class: 'col-md-4 col-sm-6 col-xs-12',
          multiple: true ,
          value: null,
        },
        {
          key: 'payment_deposit_status',
          type: 'multiple-select',
          options: this.filterBuilders.paymentStatusList,
          optionLabel: 'fr',
          optionValue: 'value',
          frontType:'select-with-fixed-list',
          strategy: '',
          label: this.$store.getters['languageMixin/getStringFromLanguage']('Payment Status (Deposit)'),
          class: 'col-md-4 col-sm-6 col-xs-12',
          multiple: true ,
          value: null,
        },
        {
          key: 'payment_balance_status',
          type: 'multiple-select',
          options: this.filterBuilders.paymentStatusList,
          optionLabel: 'fr',
          optionValue: 'value',
          frontType:'select-with-fixed-list',
          strategy: '',
          label: this.$store.getters['languageMixin/getStringFromLanguage']('Payment Status (Balance)'),
          class: 'col-md-4 col-sm-6 col-xs-12',
          multiple: true ,
          value: null,
        },
        {
          key: 'created_at',
          type: 'date',
          frontType:'simple',
          strategy: 'gt',
          label: this.$store.getters['languageMixin/getStringFromLanguage']('Created after'),
          class: 'col-md-6 col-sm-12',
          value: null,
        },
        {
          key: 'created_at',
          type: 'date',
          frontType:'simple',
          strategy: 'lt',
          label: this.$store.getters['languageMixin/getStringFromLanguage']('Created before'),
          class: 'col-md-6 col-sm-12',
          value: null,
        },
        {
          key: 'updated_at',
          type: 'date',
          frontType:'simple',
          strategy: 'gt',
          label: this.$store.getters['languageMixin/getStringFromLanguage']('updated after'),
          class: 'col-md-6 col-sm-12',
          value: null,
        },
        {
          key: 'updated_at',
          type: 'date',
          frontType:'simple',
          strategy: 'lt',
          label: this.$store.getters['languageMixin/getStringFromLanguage']('updated before'),
          class: 'col-md-6 col-sm-12',
          value: null,
        },
        {
          key: 'session_id',
          type: 'number',
          strategy: '=',
          value: this.filters.session_id,
        },
        {
          key: 'cycle_id',
          type: 'number',
          strategy: '=',
          value: this.filters.cycle_id,
        },
      ];
      this.exportKey++;
    },
    openModal (name) {
      this.modals[name] = true
    },
    closeModal (name) {
      this.modals[name] = false
    },
  },
}
</script>

<style>
  .register_status{
    color: #ffff;
  }
  .registration-table .el-table__row .cell{
    padding: 0px;
    text-align: left;
    word-break: keep-all !important;
  }
</style>

